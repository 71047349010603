import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import JwtService from "../../services";
import { useLayoutEffect, useMemo, useState } from "react";
import utilityService from "@fuse/services/utilityService";
import { IApiResponse } from "@fuse/models/user.models";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import {
	mixpanelHelper,
	emitter,
} from "@marvelapp/react-ab-test";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";
import { Rating } from "@mui/material";
import { ratingColors } from "@fuse/colors";
import { useGoogleLogin } from "@react-oauth/google";
import jwtService from "../../services/jwtService";
import { UserType } from "app/store/user";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import HTMLReactParser from "html-react-parser";

declare var mixpanel: any;

mixpanelHelper.enable();

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup
		.string()
		.email("You must enter a valid email")
		.max(255, "Email can't be more than 255 characters long")
		.required("You must enter a email"),
});

const CustomPrimaryButton = ({
	bgClass,
	roundedClass,
	children,
	disabled = false,
	onClick,
}) => {
	const bgClassName = bgClass ? bgClass : "bg-peeva-yellow";
	const roundedClassName = roundedClass ? roundedClass : "rounded-[32px]";
	const className = `w-full sm:w-auto sm:h-56px h-48px ${roundedClassName} ${bgClassName} border py-10 sm:text-16px font-700 sm:leading-[18.75px] text-14px leading-16px text-peeva-black`;
	return (
		<Button
			variant="contained"
			className={className}
			aria-label={children}
			type="submit"
			size="large"
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

/**
 * The sign up page.
 */
function SignUpHome() {
	const dispatch = useAppDispatch();
	const fbClientId = useMemo(() => process.env.VITE_APP_FACEBOOK_ID, [process.env.VITE_APP_FACEBOOK_ID]);

	const defaultValues = {
		email: getStoredEmail(),
	};

	const { control, formState, trigger, handleSubmit, setError, clearErrors } =
		useForm({
			mode: "onChange",
			defaultValues,
			resolver: yupResolver(schema),
		});

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [clientUrl, setClientUrl] = useState("");
	const [customError, setCustomError] = useState("");

	const { isValid, dirtyFields, errors } = formState;

	useLayoutEffect(() => {
		getClientSettings();
		JwtService.clearHistory();
	}, []);

	async function getClientSettings() {
		const res =
			(await utilityService.getExistingClientSettings()) as IApiResponse;
		if (res.succeeded) {
			setClientUrl(res.result.clientSiteBase);
		}
	}

	function onSubmit({ email }: typeof defaultValues) {
		setLoading(true);
		sessionStorage.setItem("email", email);
		JwtService.checkIfEmailExists(email)
			.then((res) => {
				hideLoading();
				navigate(`/sign-up-form/?email=${encodeURIComponent(email)}`);
			})
			.catch(
				(
					_errors: { type: "email" | "service_unavailable"; message: string }[]
				) => {
					hideLoading();
					const service_unavailable_error = _errors.find(
						(c) => c.type == "service_unavailable"
					);

					if (service_unavailable_error) {
						dispatch(
							showMessage({
								message: service_unavailable_error.message,
								variant: "error",
							})
						);
					} else {
						_errors.forEach((error) => {
							setError("email", {
								type: "manual",
								message: error.message,
							});
						});
					}
				}
			);
	}

	function hideLoading() {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}

	function getStoredEmail() {
		const email = sessionStorage.getItem("email") as string;
		if (email) {
			const stEmail = email as string;
			return stEmail;
		}
		return "";
	}

	emitter.addPlayListener(function (experimentName, variantName) {
		//mixpanel.track("Start Experiment", {name: experimentName, variant: variantName});
		//@ts-ignore
		window.dataLayer = window.dataLayer || [];
		//@ts-ignore
		dataLayer.push({
			event: "experiment_play",
			experiment_name: experimentName,
			experiment_variant: variantName,
		});
	});

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			setLoading(true);
			const { access_token } = tokenResponse;
			getUserInfo(access_token);
		},
		onError: (errorResponse) => {
			setLoading(false);
			console.log("Login Failed:", errorResponse);
		},
		flow: "implicit",
	});

	const responseFacebook = async (response) => {
		console.log('facebook response', response);
		jwtService.facebookSignIn(response).then((user: UserType) => {
			setLoading(false);
		})
			.catch(
				(
					_errors: {
						type: "email" | "password" | `root.${string}` | "root";
						message: string;
					}[]
				) => {
					setLoading(false);
					setCustomError(_errors[0].message);

					setTimeout(() => {
						setCustomError("");
					}, 8000);
				}
			);
	}

	const getUserInfo = async (token) => {
		try {
			const response = await fetch(
				"https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setLoading(true);
			const data = await response.json();
			const userData = {
				email: data?.email,
				family: data?.family_name,
				given: data?.given_name,
				hd: data?.hd,
				id: data?.id,
				name: data?.name,
				picture: data?.picture,
				Verified: data?.verified_email,
			};
			console.log("User info:", userData);
			if (data) {
				jwtService
					.googleSignIn(userData, true)
					.then((user: UserType) => {
						setLoading(false);
						console.info(user);
						localStorage.removeItem("usernameOrEmail");
					})
					.catch(
						(
							_errors: {
								type: "email" | "password" | `root.${string}` | "root";
								message: string;
							}[]
						) => {
							setLoading(false);
							console.error(_errors[0].message);

							setTimeout(() => {
								console.error("");
							}, 8000);
						}
					);
			}
		} catch (error) {
			console.error("Error fetching user info:", error);
		}
	};

	return (
		<>
			<div className="flex min-w-0 flex-auto flex-col bg-white items-center md:flex-row sm:justify-center md:items-start md:justify-start gap-32">
				{loading && <FuseSplashScreen />}
				<div className="w-full sm:h-auto sm:w-auto px-16 pt-64 sm:p-[48px] md:flex md:h-full md:w-1/2 md:justify-end md:rounded-none md:p-[40px_80px] gap-[48px]">
					<div className="mx-auto max-w-2xl">
						<img
							className="h-40px w-[110px] flex mx-auto"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
						<Typography className="mt-14 sm:text-32px text-28px font-700 font-ro text-center sm:leading-38px leading-32 tracking-tight text-black">
							Protect Your Pet for Life
						</Typography>
						<div className="mt-14 flex justify-center items-baseline font-medium">
							<Typography className="sm:text-16px text-14px font-400 sm:leading-18px leading-16px text-black text-center">
								Enroll any brand of microchip in our Central Pet Recovery
								Database.
							</Typography>
						</div>

						{customError && (
							<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
								<Alert
									variant="filled"
									severity="error"
									onClose={() => {
										setCustomError("");
									}}
								>
									<p>{HTMLReactParser(customError)}</p>
								</Alert>
							</Stack>
						)}

						<div className="mt-32 flex w-full flex-col justify-center ">
							<Button
								variant="contained"
								className="w-full sm:w-auto sm:h-58px h-58px rounded-[16px] bg-white border-solid border-2 border-grey hover:bg-white-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
								aria-label="Sign up with Google"
								type="button"
								size="large"
								onClick={() => login()}
							>
								<>
									<img
										className="h-[16px] w-[16px]"
										src="assets/img/Googlelogo.svg"
										alt="logo"
									/>
									&nbsp;Sign up with Google
								</>
							</Button>

							{
								fbClientId && (<div className="py-4 flex sm:w-auto w-full">
									<FacebookLogin
										appId={fbClientId}
										autoLoad={false}
										fields="id,name,email,first_name,last_name,gender,location"
										callback={responseFacebook}
										className="py-28"
										render={renderProps => (
											<Button

												variant="contained" className="w-full sm:h-58px h-58px rounded-[16px] bg-white border-solid border-2 border-grey hover:bg-white-110 transition-colors duration-200 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black px-20 py-28"
												onClick={renderProps.onClick}>
												<FacebookRoundedIcon className="h-[25px] w-[25px] text-peeva-blue mr-3" />
												Sign up with Facebook</Button>
										)}
									/>
								</div>)
							}

							<div className="flex items-center mt-20">
								<div className="flex-grow border-b border-gray-300"></div>
								<div className="mx-4 text-black text-sm">OR</div>
								<div className="flex-grow border-b border-gray-300"></div>
							</div>
						</div>

						<form
							name="registerForm"
							noValidate
							className="mt-20 flex w-full flex-col justify-center"
							id="signuphome-form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
										label="Email"
										type="email"
										autoFocus
										error={!!errors.email}
										helperText={errors?.email?.message}
										required
										fullWidth
										inputProps={{ maxLength: 255 }}
										InputLabelProps={{
											style: {
												lineHeight: "4rem",
											},
										}}
									/>
								)}
							/>
							<CustomPrimaryButton
								bgClass="bg-peeva-blue hover:bg-peeva-blue-110 transition-colors duration-200 text-white"
								roundedClass="rounded-[16px]"
								onClick={() => {
									trigger();
								}}
							>
								Sign up with email
							</CustomPrimaryButton>
						</form>
						<div className="mt-12 mx-auto flex items-baseline font-medium gap-[20px]">
							<Typography className="text-12px text-center md:text-left font-400 sm:leading-18px leading-14px text-peeva-black-120">
								By clicking the button above, you agree to our{" "}
								<a
									href="https://peeva.co/terms-of-service"
									className="!underline"
									role="button"
									target="_blank"
								>
									Terms of Service
								</a>{" "}
								and{" "}
								<a
									href="https://peeva.co/privacy"
									className="!underline"
									role="button"
									target="_blank"
								>
									Privacy Policy
								</a>
								. Peeva cares about your privacy. Your data will only be used to
								process your order and support your experience. Peeva will never
								sell, trade or transfer your personal data to third-parties.
							</Typography>
						</div>

						<div className="mt-48 mx-auto flex justify-center items-baseline sm:text-16px text-14px font-400 sm:leading-18px leading-16px gap-[20px]">
							<Typography className="text-black sm:text-16px text-14px font-400 sm:leading-18px leading-16px">
								Already a member?
							</Typography>
							<Link
								className="ml-4 text-blue sm:text-16px !no-underline text-14px font-700 sm:leading-18px leading-16px"
								to={"/sign-in"}
							>
								Sign in
							</Link>
						</div>
					</div>
				</div>

				{/* second half */}
				<div className="bg-peeva-blue flex justify-between flex-col h-[100vh] md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 relative sm:h-auto sm:shadow sm:w-auto max-h-[100vh]">
					<div className="relative pt-5 w-full md:p-64 p-32 sm:p-48 z-99">
						<div className="tracking-tight text-gray-400 text-20px font-700 leading-28px">
							<Rating
								name="read-only"
								value={5}
								readOnly
								className="flex pb-24 sm:pb-12"
								sx={{
									"& .MuiRating-iconFilled": {
										color: ratingColors.default,
									},
								}}
							/>
							<Typography className="text-black my-8 sm:text-20px font-700 sm:leading-28px text-14px leading-20px">
								"My pet was lost. Peeva very kindly walked me through how to
								activate a lost pet alert. A few hours later, my pet was found!
								I love that I can trust a human being is answering my questions
								of stress. I am so happy to be registered with Peeva."
							</Typography>
							<Typography className="text-black flex mt-20 sm:text-16px font-400 sm:leading-22px text-14px leading-20px">
								- Elizabeth A.
							</Typography>
						</div>
					</div>

					<div className="relative z-10 pb-0 pt-[120px] w-full h-full signup-home-warapper">
						<div className="text-lg leading-6 tracking-tight text-gray-400 h-[100vh]">
							<img
								className="w-full flex mx-auto absolute bottom-[20em]"
								src="assets/img/signupIcon3.svg"
								alt="logo"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SignUpHome;
